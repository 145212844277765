<template>
  <div class="relative mt-[85px] mb-[200px]">
    <img src="@/assets/img/miko.gif" class="relative w-[274px] z-10" alt="">
    <img src="@/assets/img/paper.webp" class="absolute top-[15px] z-0 scale-200">
    <img src="@/assets/img/portfolio.webp" class="absolute top-[135px] left-[-130px] w-[369px] portfolio-scale z-20" alt="">
    <img src="@/assets/img/heart_sticker.webp" class="absolute z-20 heart-sticker" alt="">
    <img src="@/assets/img/strawb-sticker.webp" class="absolute z-20 strawb-sticker" alt="">
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>
.heart-sticker {
  width: 60px;
  transform: rotate(-5deg);
  top: -25px;
  left: -25px
}

.strawb-sticker {
  width: 60px;
  transform: rotate(5deg);
  bottom: -15px;
  right: -25px
}

.portfolio-scale {
  transform: scale(1.35);
}
.scale-200 {
  transform: scale(2.05);
  rotate: 83deg;
}
</style>