<template>
  <div class="w-[960px] relative">
    <masonry-wall :items="images" :min-columns="2" :max-columns="2" :gap="16">
      <template #default="{ item, index }">
       <img :src="item" alt="Image" @click="imageViewer(index)" class="hover:opacity-75"/>
      </template>
    </masonry-wall>

    <img src="/img/jebe.png" alt="Image" class="absolute top-[35px] w-[120px] left-[-130px]" />
    <img src="/img/jebe2.png" alt="Image" class="absolute top-[1750px] w-[120px] left-[975px]" />
    <img src="@/assets/img/strawb-sticker.webp" alt="Image" class="absolute top-[300px] w-[60px] left-[435px] rotate-[-30deg]" />
    <img src="@/assets/img/strawb-sticker.webp" alt="Image" class="absolute top-[1585px] w-[60px] left-[15px] rotate-[-30deg]" />
    <img src="@/assets/img/heart_sticker.webp" alt="Image" class="absolute top-[1200px] w-[60px] left-[880px] rotate-[45deg]" />
    <img src="@/assets/img/heart_sticker.webp" alt="Image" class="absolute top-[2075px] w-[60px] left-[440px] rotate-[45deg]" />
    <image-viewer ref="imageViewer" :images="images"></image-viewer>

  </div>

  
</template>

<script>
import ImageViewer from "./ImageViewer.vue";

export default {
  components: {
    ImageViewer,
  },
  data() {
    return {
      images: [
        "/img/ot9.jpg",
        "/img/drinking.jpg",
        "/img/screech.jpg",
        "/img/guys.jpg",
        "/img/kiss.jpg",
        "/img/trio.jpg",
        "/img/sword.jpg",
        "/img/wtf.jpg",
        "/img/molly.jpg",
        "/img/super.jpg",
        "/img/walk.jpg",
      ],
    };
  },
  methods: {
    imageViewer(image) {
      this.$refs.imageViewer.open(image);
    },
  },
};
</script>

<style>
.masonry {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Exactly 2 columns */
  gap: 16px; /* Space between items */
}

.masonry-item {
  break-inside: avoid; /* Prevent breaking items between columns */
}

img {
  display: block;
  width: 100%; /* Make images fit their container */
  height: auto; /* Maintain aspect ratio */
}
</style>