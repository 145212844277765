<template>
  <div class="bg-[#ff759a] w-full mt-6 text-white text-center p-3 text-2xl">
    Contact me at
    <div class="flex justify-center items-center gap-4">
      <a href="https://x.com/uwutimestwo"><img src="@/assets/img/twitter.webp" class="w-[39px] hover:opacity-75" alt=""></a>
      <a href="https://www.instagram.com/uwutimestwo/"><img src="@/assets/img/insta.webp" class="w-[39px] hover:opacity-75" alt=""></a>
      <a href="https://www.tumblr.com/blog/uwutimestwo-art"><img src="@/assets/img/tumblr.webp" class="w-[39px] hover:opacity-75" alt=""></a>
      <a href="https://artistree.io/uwutimestwo"><img src="@/assets/img/artistree.png" class="w-[35px] hover:opacity-75" alt=""></a>
      <a href="https://www.youtube.com/@uwutimestwo"><img src="@/assets/img/youtube.webp" class="w-[39px] hover:opacity-75" alt=""></a>
    </div>
    <div class="text-lg">uwutimestwoart@gmail.com</div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>