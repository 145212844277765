<template>
  <MikuHeader />
  <ImageGallery />
</template>

<script>
import MikuHeader from "@/components/MikuHeader.vue";
import ImageGallery from "@/components/ImageGallery.vue";

  export default {
    components: { MikuHeader, ImageGallery },
  }
</script>

<style scoped>

</style>