<template>
  <div class="w-[160px] hover:bg-red-100 text-center text-red-600">
    <router-link :to="route" active-class="text-white"><slot></slot></router-link>
  </div>
</template>

<script>
export default {
  props: {
    route: String
  }
}
</script>

<style scoped>

</style>