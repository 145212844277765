<template>
  <div>
    <img class="w-[400px]" src="@/assets/img/under_construction.webp" alt="">
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>