<template>
  <div class="min-h-screen w-full flex flex-col items-center justify-center">
    <img src="@/assets/img/uwutimestwo.webp" class="h-[80px] w-[377px]" alt="logo">
    <MenuBar></MenuBar>
    <RouterView class="flex-grow"></RouterView>
    <FooterBar class="sticky top-[100vh]"></FooterBar>
  </div>
</template>

<script>
import MenuBar from "./components/MenuBar.vue";
import FooterBar from "./components/FooterBar.vue";

export default {
  name: 'App',
  components: {
    MenuBar,
    FooterBar
  }
}
</script>

<style>
@font-face {
  font-family: 'Franxurter';
  src: url('@/assets/FranxurterTotallyMedium-gxwjp.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Franxurter', sans-serif;
}

</style>
