<template>
  <div class="flex justify-center items-center mt-6 mb-6">
    <MenuItem :route="'/'">portfolio</MenuItem>
    <MenuItem :route="'/shop'">shop</MenuItem>
    <MenuItem :route="'/shows'">shows</MenuItem>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";

  export default {
    components: { MenuItem },
    data: function () {
      return {}
    }
  }
</script>

<style lang="scss" scoped>

</style>