<template>
  <div v-if="visible" class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <button class="close-button" @click="close">X</button>
      <img :src="images[currentIndex]" alt="Image" class="modal-image" />
      <button class="prev-button" @click="prevImage">Previous</button>
      <button class="next-button" @click="nextImage">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      currentIndex: 0,
    };
  },
  methods: {
    open(index) {
      this.currentIndex = index;
      this.visible = true;
      document.addEventListener('keydown', this.handleKeydown);
    },
    close() {
      this.visible = false;
      document.removeEventListener('keydown', this.handleKeydown);
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      }
    },
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 900px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  height: auto;
  object-fit: contain;
}

.close-button,
.prev-button,
.next-button {
  position: absolute;
  top: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.close-button {
  right: 10px;
}

.prev-button {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.next-button {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>